.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius:1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-bg);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ------------------------------ FORMS ------------------------------ */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-primary);
}

/* ------------------------------ SEND BUTTON ANIMATION ------------------------------ */

/* .button {
    position: relative;
    width: 170px;
    height: 50px;
    font-size: 12px;
    background: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: width .5s;
    border-radius: .5s;
}
button *{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .25s;
}

.icon {
    opacity: 0;
}

.button:focus {
    width: 50px;
    background-color: rgb(67, 255, 124);
    border-radius: 50%;
    animation-timing-function: ease-out;
    animation-duration: 2s;
}

.button:focus .text {
    opacity: 0;
}

.button:focus .icon {
    opacity: 1;
    transition-delay: .5s;
} */

/* ------------------------------ SCROLL UP ------------------------------ */

.scroll__up {
    position: absolute;
    right: 14rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}


/* ------------------------------ MEDIA QUERIES (MEDIUM DEVICES) ------------------------------ */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ------------------------------ MEDIA QUERIES (SMALL DEVICES) ------------------------------ */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}