/* Import your font choice here */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&family=Ubuntu:wght@300;400;500;700&display=swap');



/* This is going to apply to all elements */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* Applies to the root of the project, and any component can use these */
:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

/* Makes the scrolling more smooth */
html{
    scroll-behavior: smooth;
}

/* Disable the scrollbar */
::-webkit-scrollbar{
    display: none;
}

/* Set up font style and use the css variables to create the background color/ font color */
body {
    font-family: 'Ubuntu', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url("https://www.transparenttextures.com/patterns/dark-geometric.png");
}

/* ------------------------------ GENERAL STYLES ------------------------------ */

/* Create container with large container width */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

/* Set font weight for h tags */
h1, h2, h3, h4, h5 {
    font-weight: 500;
}

/* set font size for h1 tag */
h1 {
    font-size: 2.5rem;
}

/* Set margin top to create space between sections */
section {
    margin-top: 8rem;
    /* height: 100vh; */
}

/* Align and set font color h2 and h5 tags */
section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

/* Set section styles for h2 */
section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

/* Set text light styles with a transition */
.text-light {
    color: var(--color-white);
}

/* Hover effect style */
a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

/* Button styles */
.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

/* Button hover styles */
.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

/* Btn primary styles */
.btn-primary {
    background: var(--color-primary);
    color: var(--color-white);
}

/* Set styles for the images we will be using */
img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ------------------------------ MEDIA QUERIES (MEDIUM DEVICES) ------------------------------ */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ------------------------------ MEDIA QUERIES (SMALL DEVICES) ------------------------------ */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }
}